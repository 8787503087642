import {
    localStorageWrapper,
    getTokenReqIdFromURL,
    blockUserOnURLPaths,
    sessionStorageWrapper,
} from '.';

const getInitialReduxState = () => {
    const currentTokenReqId = getTokenReqIdFromURL();
    const oldTokenRequestId = sessionStorageWrapper.get('token-request-id');
    if (sessionStorageWrapper.get('blockUserToGoBack') === true) {
        if (oldTokenRequestId) {
            if (
                oldTokenRequestId === currentTokenReqId ||
                blockUserOnURLPaths()
            ) {
                try {
                    return localStorageWrapper.get('reduxState');
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error(e);
                }
            }
        }
    }
    if (currentTokenReqId) {
        sessionStorageWrapper.set('blockUserToGoBack', false);
        sessionStorageWrapper.set('token-request-id', currentTokenReqId);
    }
    return window.history.state;
};

export default getInitialReduxState;
