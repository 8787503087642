import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import style from './ConsentBackToTppOverlay.css';
import Overlay from '@token-io/lib-web-components/src/Components/Overlay';
import { FormattedMessage } from 'react-intl';
import TokenButtonsContainer from '@token-io/lib-web-components/src/Components/Layout/TokenButtonsContainer';
import Button from '@token-io/lib-web-components/src/Components/Button';
import TokenTitle from '@token-io/lib-web-components/src/Components/Layout/TokenTitle';
import { connect } from 'reducers';
import { terminateFlow } from 'actions/shared';
import HomeIcon from 'components/App/Icons/HomeIcon';
import { CSSTransition } from 'react-transition-group';
import { CUSTOM_TPP_FEATURES as featureConfig } from './../../config/constants';

const cx = classNames.bind(style);

// Automate animation factor
const SLIDE_AUTOMATE_FACTOR = 0.5;

// Percentage according to the extra min height required for pop-up, for refernce:- check css for toggle container
const SCALE_FACTOR = 0.07;

// React Component
const ConsentBackToTppOverlay = ({
    declineConsent,
    handleOverlay,
    tppName,
    merchantName,
    productName,
}) => {
    let initialX = null;
    let initialY = null;
    const [initialOverlayHeight, _setInitialOverlayHeight] = useState(0);
    const [currentOverlayHeight, setCurrentOverlayHeight] = useState(0);
    const ref = useRef(null);
    const toggleRef = useRef(null);
    const root = document.getElementById('app');
    const [size, setSize] = useState(root.getBoundingClientRect());
    const [showTransition, setShowTransition] = useState(false);

    // UseRef hook because to access states inside event listners.
    const initialOverlayHeightRef = useRef(initialOverlayHeight);

    const setInitialOverlayHeight = data => {
        const initialHeight = data + SCALE_FACTOR * data;
        initialOverlayHeightRef.current = initialHeight;
        _setInitialOverlayHeight(initialHeight);
        setCurrentOverlayHeight(initialHeight);
    };

    const useWindowSize = () => {
        useLayoutEffect(() => {
            const updateSize = () => {
                setSize(root.getBoundingClientRect());
            };
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    };

    const rect = useWindowSize();

    useEffect(() => {
        if (!showTransition) {
            const timeout = setTimeout(() => {
                handleOverlay(false);
            }, 400);

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [showTransition]);

    useEffect(() => {
        if (
            currentOverlayHeight &&
            currentOverlayHeight < SLIDE_AUTOMATE_FACTOR * initialOverlayHeight
        ) {
            setCurrentOverlayHeight(currentOverlayHeight - 1);
        }
        if (showTransition && currentOverlayHeight <= 0) {
            handleOverlay(false);
        }
    }, [currentOverlayHeight]);

    const onMouseUpHandler = () => {
        // Reset global psoitioning of cursor
        initialX = 0;
        initialY = 0;

        setCurrentOverlayHeight(prevHeight => {
            if (
                prevHeight >
                SLIDE_AUTOMATE_FACTOR * initialOverlayHeightRef.current
            ) {
                return initialOverlayHeightRef.current;
            }
            return prevHeight;
        });
    };

    const onMouseDownHandler = e => {
        const firstTouch = getTouches(e)?.[0];
        initialX = firstTouch?.clientX || e.clientX;
        initialY = firstTouch?.clientX || e.clientY;
    };

    const onMouseMoveHandler = e => {
        if (initialX === null || initialY === null) {
            return;
        }

        const currentX = e.touches?.[0].clientX || e.clientX;
        const currentY = e.touches?.[0].clientY || e.clientY;

        const diffX = initialX - currentX;
        const diffY = initialY - currentY;

        if (Math.abs(diffX) < Math.abs(diffY)) {
            // sliding vertically
            if (diffY < 0 && initialY !== 0) {
                // swiped down
                setCurrentOverlayHeight(prevHeight => {
                    const newHeight = prevHeight - Math.abs(diffY);
                    if (newHeight < 0) {
                        return 0;
                    }
                    return newHeight;
                });
            }
        }

        e.preventDefault();
    };

    const getTouches = e => {
        return (
            e.touches || // browser API
            e?.originalEvent?.touches
        ); // jQuery
    };

    useEffect(() => {
        setInitialOverlayHeight(ref.current.clientHeight);
        setShowTransition(true); // Slide in transition

        const evtHandlers = {
            mousemove: onMouseMoveHandler,
            mousedown: onMouseDownHandler,
            touchstart: onMouseDownHandler,
            touchmove: onMouseMoveHandler,
        };

        Object.keys(evtHandlers).forEach(name => {
            toggleRef.current.addEventListener(name, evtHandlers[name], true);
        });

        return () => {
            Object.keys(evtHandlers).forEach(name => {
                toggleRef.current.removeEventListener(
                    name,
                    evtHandlers[name],
                    false,
                );
            });
        };
    }, []);

    return (
        <div>
            <CSSTransition
                classNames="ConsentBackToTpp-overlay-transition"
                in={!!showTransition}
                timeout={400}>
                <Overlay
                    overlayClass={cx('ConsentBackToTpp-overlay')}
                    portalClass={cx('ConsentBackToTpp-portal')}
                    onMouseUp={onMouseUpHandler}
                    rectPosition={{
                        ...rect,
                        left: rect.left,
                        width: rect.width,
                        top: rect.top + rect.height - currentOverlayHeight,
                    }}>
                    <>
                        <div
                            className={cx('ConsentBackToTpp')}
                            ref={ref}
                            style={{
                                height: currentOverlayHeight
                                    ? currentOverlayHeight
                                    : undefined,
                            }}>
                            <div
                                className={cx(
                                    'ConsentBackToTpp-overlay-toggle-container',
                                )}>
                                <div
                                    className={cx(
                                        'ConsentBackToTpp-overlay-toggle',
                                    )}
                                    ref={toggleRef}>
                                </div>
                            </div>
                            <TokenTitle
                                className={cx('ConsentBackToTpp-token-title')}>
                                <div className={cx('ConsentBackToTpp-title')}>
                                    <FormattedMessage
                                        tagName={'span'}
                                        id={'common.consentOverlay.title'}
                                        values={{
                                            MERCHANT_NAME: merchantName,
                                        }}
                                    />
                                </div>
                                {!merchantName && (
                                    <HomeIcon
                                        className={'ConsentBackToTpp-home-icon'}
                                        height="47"
                                        width="47"
                                        bgColor={'#D8D8D8'}
                                    />
                                )}
                            </TokenTitle>

                            <TokenTitle sub>
                                <div
                                    className={cx(
                                        'ConsentBackToTpp-sub-title',
                                    )}>
                                    <FormattedMessage
                                        tagName={'span'}
                                        id={'common.consentOverlay.subtitle'}
                                        values={{
                                            DISPLAY_NAME: <b>{tppName}</b>,
                                            PRODUCT_NAME: productName ? (
                                                productName
                                            ) : (
                                                <FormattedMessage
                                                    tagName={'span'}
                                                    id={
                                                        'common.consentOverlay.openBanking'
                                                    }
                                                />
                                            ),
                                        }}
                                    />
                                </div>
                            </TokenTitle>

                            <div>
                                <TokenButtonsContainer className="ConsentBackToTpp-button-container">
                                    <Button
                                        type={Button.TYPE_DELETE}
                                        onClick={declineConsent}
                                        status={Button.STATUS_IDLE}
                                        tabIndex="0"
                                        text={
                                            <FormattedMessage
                                                id={
                                                    'common.consentOverlay.button.cancel'
                                                }
                                            />
                                        }
                                        className="ConsentBackToTpp-button"
                                    />
                                    <Button
                                        type={Button.TYPE_GHOST}
                                        status={Button.STATUS_IDLE}
                                        tabIndex="0"
                                        onClick={() => handleOverlay(false)}
                                        className="ConsentBackToTpp-button"
                                        text={
                                            <FormattedMessage
                                                id={
                                                    'common.consentOverlay.button.continue'
                                                }
                                                values={{
                                                    PRODUCT_NAME: productName ? (
                                                        productName
                                                    ) : (
                                                        <FormattedMessage
                                                            tagName={'span'}
                                                            id={
                                                                'common.consentOverlay.openBanking'
                                                            }
                                                        />
                                                    ),
                                                }}
                                            />
                                        }
                                    />
                                </TokenButtonsContainer>
                            </div>
                        </div>
                    </>
                </Overlay>
            </CSSTransition>
        </div>
    );
};

ConsentBackToTppOverlay.propTypes = {
    handleOverlay: PropTypes.func,
    declineConsent: PropTypes.func,
    tppName: PropTypes.string,
    merchantName: PropTypes.string,
    productName: PropTypes.string,
};

const mapStateToProps = ({ tokenRequestService }) => {
    const tppName = tokenRequestService.getTppProfile()?.tppName;
    const tppAlias = tokenRequestService.getTppAlias();
    const actingAs = tokenRequestService.getTppActingAs();
    const merchantName = actingAs?.displayName || tppName || '';
    const productName = tokenRequestService.getTppFeature(
        featureConfig.PRODUCT_NAME,
        '',
    );

    return {
        tppName:
            actingAs?.displayName ||
            tppName ||
            actingAs?.secondaryName ||
            tppAlias.value,
        merchantName,
        productName,
    };
};

const mapDispatchToProps = {
    declineConsent: () =>
        terminateFlow({
            error: 'access_denied',
            message: 'User Cancelled',
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConsentBackToTppOverlay);
